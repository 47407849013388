import React, { useEffect, useState } from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getAllOrders_completed, getAllOrders_pending, getAllOrders_picked, getAllOrders_requested, getAllOrders_paymentCompleted } from '../../store/thunks/adminActions'
import LoadingTableComponent from '../utilityComponents/LoadingTableComponent'
import ChangeDeliveryFee from '../ChangeDeliveryFee'
import { Alert } from '@mui/material'
import AssignDriver from '../AssignDriver'
import ChangeOrderStatus from '../ChangeOrderStatus'

const OrderTable = ({ data, limit, loading, driverData = null, isManageOrderStatus=false }) => {

    const dispatch = useDispatch()
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const status = data && data[0]?.status
    useEffect(() => {
        if (status) {
            switch (status) {
                case 'pending':
                    dispatch(getAllOrders_pending({ limit }))
                    break;
                case 'completed':
                    dispatch(getAllOrders_completed({ limit }))
                    break;
                case 'picked':
                    dispatch(getAllOrders_picked({ limit }))
                    break;
                case 'paymentCompleted':
                    dispatch(getAllOrders_paymentCompleted({ limit }))
                    break;
                case 'requested':
                    dispatch(getAllOrders_requested({ limit }))
                    break;
                default:
                    break;
            }
        }
    }, [limit, message]);

    return (
        <>
            {message &&
                <Alert
                    severity="success"
                    sx={{
                        position: "fixed",
                        width: "90%",
                        margin: "auto",
                        top: 20,
                        left: 0,
                        right: 0,
                        zIndex: 10,
                    }}
                >
                    {message}
                </Alert>
            }
            {error &&
                <Alert
                    severity="error"
                    sx={{
                        position: "fixed",
                        width: "90%",
                        margin: "auto",
                        top: 20,
                        left: 0,
                        right: 0,
                        zIndex: 10,
                    }}
                >
                    {message}
                </Alert>
            }
            <div className="relative overflow-x-auto border-[0.5px] border-solid border-[#CCCCCC] rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-[black] text-[white]">
                    <tr>
                        <th className="px-4 py-3">
                            Order ID
                        </th>
                        <th className="px-4 py-3">
                            Delivery Address
                        </th>
                        <th className="px-4 py-3">
                            Delivery Date
                        </th>
                        <th className="px-4 py-3">
                            Order Amount
                        </th>
                        <th className="px-4 py-3">
                            Delivery Fee
                        </th>
                        <th className="px-4 py-3">
                            Tip
                        </th>
                        <th className="px-4 py-3">
                            Driver Pay
                        </th>
                        {(status === 'pending' || status === 'requested') && !isManageOrderStatus &&
                            <th className="px-4 py-3">
                                Assign Driver
                            </th>
                        }

                        {isManageOrderStatus &&
                            <th className="px-4 py-3">
                                Status
                            </th>
                        }
                        <th className="px-4 py-3">
                            Driver
                        </th>
                        <th className="px-4 py-3">
                            Company
                        </th>
                        <th className="px-4 py-3 flex justify-center">
                            Action
                        </th>
                    </tr>
                    </thead>
                    {loading ? (
                        <LoadingTableComponent cols={(status === 'pending' || status === 'requested' || isManageOrderStatus) ? 11 : 10} />
                    ) : (

                        data?.length > 0 ? data.map((entry, index) => (
                            <tbody>
                                <tr
                                    key={entry._id}
                                    className="bg-white border-b-[#CCCCCC] border-b-[0.5px] py-4"
                                >
                                    <td className="px-4 w-fit max-w-[170px]">
                                        <p className='truncate'>{entry.uniqueId ? entry.uniqueId : entry._id}</p>
                                    </td>

                                    <td className="px-4 w-fit max-w-[200px]">
                                        <p className='truncate'>{`${entry?.property?.address?.line1} ${entry?.property?.address?.line2 ? entry?.property?.address?.line2 : ''} ${entry?.property?.address?.city} ${entry?.property?.address?.state}`}</p>
                                    </td>

                                    <td className="px-4 w-fit min-w-[120px]">
                                        <p className='truncate'>{entry.inStockDate.substr(0, 10)}</p>
                                    </td>

                                    <td className="px-4 w-fit min-w-[50px]">
                                        <p className='truncate'>{(entry.hostFee + entry.driverFee + entry.bellhoptFee + entry.groceryFee).toFixed(2)}</p>
                                    </td>

                                    <td className="px-4 w-fit min-w-[50px]">
                                        <p className='truncate'>${entry.driverFee}</p>
                                    </td>

                                    <td className="px-4 w-fit min-w-[50px]">
                                        <p className='truncate'>${entry.tip}</p>
                                    </td>

                                    <td className="px-4 w-fit min-w-[50px]">
                                        <p className='truncate'>{entry.driverPay ? '$'+entry.driverPay.toFixed(2) : ''}</p>
                                    </td>

                                    {(status === 'pending' || status === 'requested') && !isManageOrderStatus &&
                                        <td className="px-4 w-fit min-w-[50px]">
                                            <AssignDriver entry={entry} setError={setError} setMessage={setMessage} driverData={driverData}/>
                                        </td>
                                    }

                                    {isManageOrderStatus && 
                                        <td className="px-4 py-3">
                                            {entry.status.toUpperCase()}
                                        </td>
                                    }

                                    <td className="px-4 w-fit min-w-[50px]">
                                        <p className='truncate'>{ entry?.driver?.firstName }</p>
                                    </td>

                                    <td className="px-4 w-fit min-w-[50px]">
                                        <p className='truncate'>{ entry?.company }</p>
                                    </td>

                                    <td className="py-4 px-4 w-fit flex ml-auto">
                                        {
                                            entry.status === 'picked'
                                                && <NavLink to={`/track-order/${entry._id}`} className="rounded-[6px] border border w-[60px] h-[30px] flex items-center mr-2 justify-center">
                                                    Track
                                                </NavLink>
                                        }
                                        {
                                            (entry.status === 'paymentCompleted'  || entry.status === 'requested') && !isManageOrderStatus
                                                && <ChangeDeliveryFee entry={entry} setMessage={setMessage}/>
                                        }
                                        {
                                            isManageOrderStatus
                                                && <ChangeOrderStatus entry={entry} setMessage={setMessage}/>
                                        }
                                        <NavLink to={`/orderList/${entry._id}?order=true`} className="rounded-[6px] border border w-[60px] h-[30px] flex items-center justify-center">
                                            View
                                        </NavLink>
                                    </td>
                                </tr>
                            </tbody>
                        )) : (
                            <tr>
                                <td className="text-center p-4 px-4 w-fit min-w-[50px]" colSpan='7'>
                                    <p className='truncate'>THERE IS NOT ANY ORDER AVAILABLE HERE.</p>
                                </td>
                            </tr>
                        )
                    )}
                </table>
            </div>
        </>
    )
}

export default OrderTable
